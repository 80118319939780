export const useTypeSettingsStore = defineStore('type_settings', () => {
  const type_settings = ref({
    document: null,
    init_document: null,
    test: null,
    init_test: null,
  });
  const getTypeSettings = async ({ type, Refresh = false }) => {
    if (type_settings.value[type] && !Refresh) {
      return type_settings.value[type];
    }
    const res = await useGet('/adminapi/print/public_variable', { p_key: type });
    //console.log('====>>res', res);
    if (res.status == 200) {
      let val = JSON.parse(res.data.json_value);
      type_settings.value[type] = val;
    }
    return type_settings.value[type];
  };

  return {
    type_settings,
    getTypeSettings,
  };
});
