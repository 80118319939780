<template>
  <a-config-provider :getPopupContainer="getPopupContainer" :locale="lang">
    <div class="stepin-view" style="transform: scale(1)"><router-view /></div>
  </a-config-provider>
</template>

<script setup>
  import zhCN from 'ant-design-vue/es/locale/zh_CN';
  import { useTypeSettingsStore } from '@/store/type_settings';
  import { onMounted } from 'vue';

  const lang = ref(null);
  lang.value = zhCN;
  const getPopupContainer = () => {
    return document.body;
  };
  useTypeSettingsStore().getTypeSettings({ type: 'document' });
  useTypeSettingsStore().getTypeSettings({ type: 'pdd' });
  useTypeSettingsStore().getTypeSettings({ type: 'pc' });
  useTypeSettingsStore().getTypeSettings({ type: 'custom' });
</script>

<style lang="less">
  .stepin-view {
    ::-webkit-scrollbar {
      width: 4px;
      height: 8px;
      border-radius: 4px;
      background-color: theme('colors.primary.500');
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: theme('colors.primary.400');

      &:hover {
        background-color: theme('colors.primary.500');
      }
    }

    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 1px rgba(0, 0, 0, 0);
      border-radius: 4px;
      background: theme('backgroundColor.layout');
    }
  }

  html {
    height: 100vh;
    overflow-y: hidden;
  }

  body {
    margin: 0;
    height: 100vh;
    overflow-y: hidden;
  }
  .stepin-img-checkbox {
    @apply transition-transform;
    &:hover {
      @apply scale-105 ~"-translate-y-[2px]";
    }
    img {
      @apply shadow-low rounded-md transition-transform;
    }
  }
</style>
