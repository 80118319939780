import { RouteRecordRaw } from 'vue-router';

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    //  redirect: '/home',
    meta: {
      title: '首页',
      renderMenu: false,
      icon: 'CreditCardOutlined',
    },
    component: () => import('@/pages/yundayin/index.vue'),
    // component: () => import('@/pages/yundayin/index.vue'),
  },
  // {
  //   path: '/yundayin1',
  //   name: 'yundayin1',
  //   meta: {
  //     title: '云打印',
  //     renderMenu: false,
  //     icon: 'CreditCardOutlined',
  //   },
  //   component: () => import('@/components/layout/BlankView.vue'),
  //   children: [

  //   ],
  // },
  {
    path: '/front',
    name: '前端',
    meta: {
      renderMenu: false,
    },
    component: () => import('@/components/layout/BlankView.vue'),
    children: [
      // {
      //   path: '/login',
      //   name: '登录',
      //   meta: {
      //     icon: 'LoginOutlined',
      //     view: 'blank',
      //     target: '_blank',
      //     cacheable: false,
      //   },
      //   component: () => import('@/pages/login'),
      // },

      {
        path: '/home',
        name: '云打印',
        meta: {
          renderMenu: false,
          view: 'blank',
        },
        component: () => import('@/pages/yundayin/index.vue'),
      },
      {
        path: '/login',
        name: '云打印',
        meta: {
          view: 'blank',
        },
        component: () => import('@/pages/yundayin/index.vue'),
      },
    ],
  },
  {
    path: '/403',
    name: '403',
    props: true,
    meta: {
      renderMenu: false,
    },
    component: () => import('@/pages/Exp403.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    props: true,
    meta: {
      icon: 'CreditCardOutlined',
      renderMenu: false,
      cacheable: false,
      _is404Page: true,
    },
    component: () => import('@/pages/Exp404.vue'),
  },
];

export default routes;
